@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

:root {
  --bgBody: #161833;
  --bgDarkApp: #090B29;
  --fucsiaMisionTic: #f705bb;
  --moradoMisionTic: #5405ef;;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  /* background-color: var(--bgBody); */
  background-image: url("../Img/bg-misionTic2022.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bgDarkApp{
  background-color: var(--bgDarkApp);
}

.fucsiaMisionTic{
  background-color: var(--fucsiaMisionTic);
}

.moradoMisionTic{
  background-color: var(--moradoMisionTic);
}